
import { defineComponent } from "vue";
import CreatePwdForm from "@/components/forms/CreatePwdForm.vue";
import CenterCardLayout from "@/components/layouts/CenterCardLayout.vue";

export default defineComponent({
  components: {
    CenterCardLayout,
    CreatePwdForm
  }
});
