<template>
  <center-card-layout>
    <template #leftImage>
      <img src="@/assets/images/lady-working.svg" class="responsive" />
    </template>
    <update-pwd-form />
  </center-card-layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UpdatePwdForm from "@/components/forms/UpdatePwdForm.vue";
import CenterCardLayout from "@/components/layouts/CenterCardLayout.vue";

export default defineComponent({
  components: {
    CenterCardLayout,
    UpdatePwdForm
  }
});
</script>


<style scoped lang="scss">
.responsive {
  width: 100%;
  height: auto;
}
</style>