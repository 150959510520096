<template>
  <div class="py-5">
    <h1 class="text-primary">Create Password</h1>
    <p class="d-flex align-items-center"><MailOutlined class="me-3" /> {{account?.account_email}}</p>
    <a-form
      layout="vertical"
      ref="formRef"
      :model="formState"
      :rules="rules"
      @finish="handleFormFinish"
    >
      <a-form-item label="Password" required has-feedback name="password">
        <a-input
          v-model:value="formState.password"
          placeholder="your password"
          type="password"
          size="large"
          autocomplete="master-password"
        />
      </a-form-item>
      <a-form-item
        label="Confirm Password"
        required
        has-feedback
        name="confirmPassword"
      >
        <a-input
          v-model:value="formState.confirmPassword"
          placeholder="Re-enter password"
          type="password"
          size="large"
          autocomplete="master-confirmpassword"
        />
      </a-form-item>
      <a-form-item>
        <div class="d-flex justify-content-center">
          <a-button :loading="loading" block type="primary" html-type="submit"
            >Submit</a-button
          >
        </div>
      </a-form-item>
    </a-form>
  </div>
  <AlertModal
    v-model:visible="visibleModal"
    :visibleFooter="false"
    :message="alertMessage"
    @close-alert-modal="visibleModal = false"
  >
    <p class="text-center">
      {{ alertMessage }}
    </p>
    <p class="text-center mb-3 fw-light" :style="{fontSize: '2rem'}">
      <a-typography-text :type="alertType">
        <ExclamationCircleOutlined v-if="alertType === 'danger'" />
        <CheckCircleFilled v-else />
      </a-typography-text>
    </p>
    <!-- <p class="small text-muted text-center mb-4">ยืนยันสิทธ์ของท่านเรียบร้อยแล้ว</p> -->
    <p class="text-center">
      <a-button class="" type="primary" @click="handleModalClose">OK</a-button></p>
  </AlertModal>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, UnwrapRef, computed, watch } from "vue";
import { RuleObject } from 'ant-design-vue/es/form/interface';
import { useRouter } from "vue-router";
import { authService } from "@/_services";
import { ICreatePasswordPayload, ICreatePwdDto } from "@/_utils/types";
import AlertModal from "@/components/AlertModal.vue";
import { CheckCircleFilled, MailOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useStore } from 'vuex'
import { key } from '@/store'

export default defineComponent({
  components: {
    AlertModal,
    CheckCircleFilled,
    ExclamationCircleOutlined,
    MailOutlined
  },
  setup() {
    const store = useStore(key)
    const account = computed(() => store.getters.account)
    watch(
      () => account,
      (account) => {
        if(!account?.value){
          //
        }
      }, { immediate: true }
    )
    const router = useRouter();
    const visibleModal = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const alertMessage = ref<string>("ยืนยันสิทธ์ของท่านแล้ว");
    const alertType = ref<"success"|"danger">()
    const formRef = ref();
    const formState: UnwrapRef<ICreatePasswordPayload> = reactive({
      password: "",
      confirmPassword: "",
    });
    let validatePass = async (rule: RuleObject, value: string) => {
      if (value === '') {
        // return Promise.reject('Please input the password');
      } else {
        // if (!value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)){
        //   return Promise.reject('รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร และประกอบไปด้วย ตัวพิมพ์ใหญ่, ตัวพิมพ์เล็ก และตัวเลข');
        // }
        if (formState.confirmPassword !== '') {
          formRef.value.validateFields('confirmPassword');
        }
        return Promise.resolve();
      }
    };
    let validatePass2 = async (rule: RuleObject, value: string) => {
      if (value === '') {
        // return Promise.reject('Please input the password again');
      } else if (value !== formState.password) {
        return Promise.reject("Password doesn't match!");
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      password: [{ validator: validatePass, trigger: 'change' }, { required: true, message: 'Please input the password'}],
      confirmPassword: [{ validator: validatePass2, trigger: 'change' }, { required: true, message: 'Please input the password again'}],
    };

    const handleFormFinish = () => {
      if(!formState.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)){
        alertType.value = 'danger'
        alertMessage.value = 'รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร และประกอบไปด้วย ตัวพิมพ์ใหญ่, ตัวพิมพ์เล็ก และตัวเลข'
        visibleModal.value = true
        return
      }
      if(!account.value) {
        alertType.value = 'danger'
        alertMessage.value = 'Account not found'
        visibleModal.value = true
        return
      }
      const { account_id, account_password_verified_token, account_email } = account.value
      const payload: ICreatePwdDto = {
        token: `${account_password_verified_token}`,
        account_email,
        account_password: formState.password,
        confirm_password: formState.confirmPassword
      }
      loading.value = true
      authService.createPassword(+account_id, payload)
      .then(result => {
        if(!result){
          alertType.value = 'danger'
          alertMessage.value = 'Invalid token'
          visibleModal.value = true
          return
        }
        alertMessage.value = 'Password created successfully'
        visibleModal.value = true
        alertType.value = 'success'
      }, error => {
        alertMessage.value = 'Invalid token'
        visibleModal.value = true
        alertType.value = 'danger'
      })
      .finally(() => loading.value = false)
    };

    const handleModalClose = () => {
      const type = alertType.value
      visibleModal.value = false
      setTimeout(() => {
        alertMessage.value = ''
        alertType.value = undefined
        if(type === 'success') {
          router.replace({ name: 'login' })
        }
      }, 500)
    }

    return {
      formRef,
      rules,
      formState,
      handleFormFinish,
      loading,
      visibleModal,
      alertMessage,
      account,
      handleModalClose,
      alertType
    };
  },
});
</script>
